import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>O nas | Poznaj Bexovite Arena</title>
        <meta
          name={"description"}
          content={"Odkryj z nami ducha piłki nożnej"}
        />
        <meta property={"og:title"} content={"O nas | Poznaj Bexovite Arena"} />
        <meta
          property={"og:description"}
          content={"Odkryj z nami ducha piłki nożnej"}
        />
        <meta
          property={"og:image"}
          content={"https://bexovite.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://bexovite.com/img/753354.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://bexovite.com/img/753354.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://bexovite.com/img/753354.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://bexovite.com/img/753354.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://bexovite.com/img/753354.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://bexovite.com/img/753354.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="150px 0 150px 0"
        background="rgba(0, 0, 0, 0) url(https://bexovite.com/img/4.jpg) 0% 0% /cover repeat scroll padding-box"
        sm-padding="80px 0 80px 0"
        quarkly-title="Product-7"
      >
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="flex-end"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="400px"
            background="#ffffff"
            padding="50px 35px 50px 35px"
            display="flex"
            align-items="center"
            flex-direction="column"
            md-padding="35px 35px 35px 35px"
          >
            <Text
              margin="0px 0px 20px 0px"
              font="normal 600 32px/1.2 --fontFamily-sans"
              text-align="center"
            >
              O nas
            </Text>
            <Text
              margin="0px 0px 35px 0px"
              text-align="center"
              font="normal 400 18px/1.5 --fontFamily-sansHelvetica"
            >
              Bexovite Arena narodziła się z prostej wizji: zapewnienia
              osobistego, profesjonalnego środowiska piłkarskiego, w którym
              entuzjaści na wszystkich poziomach mogą się rozwijać. Od naszych
              skromnych początków jako małe boisko społecznościowe, staliśmy się
              głównym miejscem dla miłośników piłki nożnej, którzy szukają
              wyrafinowanego miejsca do uprawiania swojego ulubionego sportu.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        padding="100px 0 100px 0"
        background="#ffffff"
        quarkly-title="FAQ-5"
      >
        <Text
          margin="0px 0px 15px 0px"
          font="normal 600 42px/1.2 --fontFamily-sans"
          color="--darkL1"
        >
          Nasze obiekty
        </Text>
        <Text
          margin="0px 0px 70px 0px"
          font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
          color="#555a5f"
          sm-margin="0px 0px 50px 0px"
        >
          To, co wyróżnia Bexovite, to nie tylko nasze najnowocześniejsze boisko
          do piłki nożnej, ale także staranne przemyślenie każdego zakątka
          naszej areny. Od szatni zaprojektowanych z myślą o komforcie i
          łatwości użytkowania, po strefy dla widzów, które oferują wyraźny,
          niezakłócony widok na boisko, każdy szczegół został opracowany tak,
          aby poprawić wrażenia z gry w piłkę nożną.
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-gap="50px 50px"
        >
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 500 25px/1.2 --fontFamily-sans"
              color="--darkL1"
            >
              Dlaczego Bexovite się wyróżnia
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
              color="#555a5f"
            >
              Profesjonalne boisko: Wyposażone w najnowszą technologię murawy,
              nasze boisko oferuje spójną, redukującą kontuzje nawierzchnię,
              która rywalizuje z profesjonalnymi stadionami.
              <br />
              <br />
              Przyjazne dla widzów: Podwyższone siedzenia i strategiczne
              oświetlenie zapewniają, że żaden moment akcji nie zostanie
              pominięty z linii bocznej.
              <br />
              <br />
              Elastyczność i wygoda: Otwarte terminy rezerwacji i łatwy dostęp
              sprawiają, że planowanie następnej gry jest tak proste, jak rzut
              karny.
              <br />
              <br />
              Koncentracja na społeczności: Wierzymy, że piłka nożna to coś
              więcej niż tylko gra, to sposób na zbliżenie ludzi, dlatego
              oferujemy specjalne stawki na wydarzenia społecznościowe.
            </Text>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Text
              margin="0px 0px 15px 0px"
              font="normal 500 25px/1.2 --fontFamily-sans"
              color="--darkL1"
            >
              Zostań częścią naszej gry
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
              color="#555a5f"
            >
              Dołącz do społeczności Bexovite i zacznij tworzyć własne
              piłkarskie historie na naszym boisku. Niezależnie od tego, czy
              grasz, aby wygrać, dobrze się bawić, czy po prostu zachować formę,
              Twoja idealna gra zaczyna się tutaj. Stwórzmy razem niezapomniane
              doświadczenia, które wykraczają poza końcowy gwizdek.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-3"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          display="flex"
          width="65%"
          align-items="flex-start"
          sm-margin="0px 0px 30px 0px"
          justify-content="center"
          lg-width="100%"
        >
          <Box
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            width="100%"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
            height="auto"
            padding="0px 0px 70% 0px"
          >
            <Image
              bottom={0}
              min-height="100%"
              src="https://bexovite.com/img/5.jpg"
              object-fit="cover"
              left={0}
              top={0}
              right={0}
              position="absolute"
              display="block"
              width="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          lg-order="1"
          width="35%"
          flex-direction="column"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
        >
          <Box
            transform="translateY(0px)"
            width="100%"
            overflow-y="hidden"
            position="relative"
            hover-transform="translateY(-10px)"
            padding="0px 0px 65% 0px"
            height="auto"
            overflow-x="hidden"
            transition="transform 0.2s ease-in-out 0s"
          >
            <Image
              bottom={0}
              src="https://bexovite.com/img/6.jpg"
              width="100%"
              left={0}
              right={0}
              min-height="100%"
              object-fit="cover"
              position="absolute"
              display="block"
              top={0}
            />
          </Box>
          <Text
            margin="24px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Wejdź na nasze boisko - gdzie Twoje piłkarskie marzenia nabierają rozpędu
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
